import { API, Auth } from 'aws-amplify'

import FormDataUtils from '@utils/form-data'
import { LOCAL_STORAGE_USER_TYPE_KEY } from '@utils/local-storage'
import {
  SESSION_STORAGE_IMPERSONATION_KEY,
  SESSION_STORAGE_USER_TYPE_KEY,
} from '@utils/session-storage'

type QueryString = { [key: string]: string | string[] | boolean }

type Headers = {
  [key: string]: string
}

enum TokenType {
  ACCESS,
  BEARER,
}

const handleUnauthorized = async () => {
  await Auth.signOut()
  window.location.href = '/signin'
}

const getJwtToken = async (tokenType: TokenType) => {
  const currentSession = await Auth.currentSession()

  if (tokenType === TokenType.BEARER) {
    return `Bearer ${currentSession.getIdToken().getJwtToken()}`
  }

  if (tokenType === TokenType.ACCESS) {
    return currentSession.getAccessToken().getJwtToken()
  }
}

const getActiveUserType = () => {
  const sessionUserTypeKey = sessionStorage.getItem(
    SESSION_STORAGE_USER_TYPE_KEY
  )
  const localUserTypeKey = localStorage.getItem(LOCAL_STORAGE_USER_TYPE_KEY)

  return sessionUserTypeKey || localUserTypeKey
}

const getImpersonation = () =>
  sessionStorage.getItem(SESSION_STORAGE_IMPERSONATION_KEY)

const buildHeaders = async (
  authenticate: boolean,
  newHeaders: {
    [key: string]: string
  } = {}
) => {
  const headers: { [key: string]: string } = {
    ...newHeaders,
  }
  const activeUserType = getActiveUserType()
  if (activeUserType) {
    headers['x-active-user-role'] = activeUserType
  }

  if (authenticate) {
    let accessToken, bearerJwtToken

    try {
      accessToken = await getJwtToken(TokenType.ACCESS)
      bearerJwtToken = await getJwtToken(TokenType.BEARER)
    } catch (err) {
      await handleUnauthorized()
      return undefined
    }

    if (accessToken) {
      headers['Access-Token'] = accessToken
    }

    if (bearerJwtToken) {
      headers.Authorization = bearerJwtToken
    }

    const impersonation = getImpersonation()

    if (impersonation) {
      headers['x-user-to-impersonate'] = JSON.parse(impersonation).userId
    }
  }

  return headers
}

export const post = async ({
  path,
  data,
  authenticate = true,
  headers = {},
  config,
  useBrowserFetch,
}: {
  authenticate?: boolean
  path: string
  data?: any
  headers?: Headers
  config?: Record<string, any>
  useBrowserFetch?: boolean
}) => {
  const newHeaders = await buildHeaders(authenticate, headers)

  if (useBrowserFetch) {
    return fetch(path, {
      method: 'POST',
      headers: newHeaders,
      body: JSON.stringify(data),
      ...config,
    })
  }

  return API.post('API', path, {
    headers: newHeaders,
    body: data,
    ...config,
  })
}

export const get = async ({
  path,
  queryString,
  authenticate = true,
  appendHeaders,
  config,
}: {
  path: string
  queryString?: QueryString
  authenticate?: boolean
  appendHeaders?: Headers
  config?: Record<string, any>
}) => {
  const headers = await buildHeaders(authenticate)

  return API.get('API', path, {
    headers: {
      ...headers,
      ...appendHeaders,
    },
    queryStringParameters: queryString,
    ...config,
  })
}

export const csv = async ({
  path,
  queryString,
  authenticate = true,
}: {
  path: string
  queryString?: QueryString
  authenticate?: boolean
}) => {
  const headers = await buildHeaders(authenticate)

  return API.get('API', path, {
    headers: {
      ...headers,
      'Content-Type': 'text/csv',
      Accept: 'text/csv',
    },
    queryStringParameters: queryString,
    responseType: 'arraybuffer',
  })
}

export const put = async ({
  path,
  data,
  authenticate = true,
  headers = {},
}: {
  authenticate?: boolean
  path: string
  data?: any
  headers?: Headers
}) => {
  const newHeaders = await buildHeaders(authenticate, headers)

  return API.put('API', path, {
    headers: newHeaders,
    body: data,
  })
}

export const remove = async ({
  path,
  queryString,
  authenticate = true,
}: {
  path: string
  queryString?: QueryString
  authenticate?: boolean
}) => {
  const headers = await buildHeaders(authenticate)

  return API.del('API', path, {
    headers,
    queryStringParameters: queryString,
  })
}

export const buildFormData = (data: Record<string, any>) => {
  const formData = new FormData()
  FormDataUtils.convertObjectToFormData(formData, data)

  return formData
}

const exports = {
  post,
  get,
  csv,
  put,
  remove,
  buildFormData,
}

export default exports
