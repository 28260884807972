import { filetypeinfo } from 'magic-bytes.js'

export const DEFAULT_MAX_FILE_SIZE_IN_MB = 20

export const CSV_FILE_MIME_TYPE = 'text/csv'

export enum AllowedStreamChatMimeTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum AllowedDocumentMimeTypes {
  PDF = 'application/pdf',
}

export enum AllowedImageMimeTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  JFIF = 'image/jfif',
}

export enum AllowedVideoMimeTypes {
  MP4 = 'video/mp4',
  MOV = 'video/quicktime',
}

export const getMimeTypeFromFileExtension = ({
  extension,
}: {
  extension: string
}): string | undefined => {
  const MIME_TYPE_INDEX = 1

  const imageFileTypes = Object.entries(AllowedImageMimeTypes)
  const videoFileTypes = Object.entries(AllowedVideoMimeTypes)
  const documentFileTypes = Object.entries(AllowedDocumentMimeTypes)

  const fileExtensions = [
    ...imageFileTypes,
    ...videoFileTypes,
    ...documentFileTypes,
  ]

  const fileMimeTypeFound = fileExtensions.find(
    ([currentExtension]) =>
      currentExtension.toLowerCase() === extension.toLowerCase()
  )?.[MIME_TYPE_INDEX]

  if (!fileMimeTypeFound) {
    return undefined
  }

  return fileMimeTypeFound
}

export const isFileBiggerThan = ({
  file,
  sizeInMb,
}: {
  file: File
  sizeInMb: number
}) => {
  const sizeLimit = sizeInMb * 1024 * 1024
  return file.size > sizeLimit
}

export const isImage = async ({ file }: { file: File }) => {
  const isImage = await isFileExtensionAllowed({
    file,
    allowedExtensions: Object.keys(AllowedImageMimeTypes),
  })
  return isImage
}

export const isFileExtensionAllowed = async ({
  file,
  allowedExtensions,
}: {
  file: File
  allowedExtensions: string[]
}) => {
  let possibleFileExtensions = await getPossibleFileExtensions({ file })

  allowedExtensions = allowedExtensions.map((v) => v.toLowerCase())
  possibleFileExtensions = possibleFileExtensions.map((v) => v.toLowerCase())

  return allowedExtensions.some((extension) =>
    possibleFileExtensions.includes(extension)
  )
}

export const getPossibleFileExtensions = async ({
  file,
}: {
  file: File
}): Promise<string[]> => {
  const fileBytes = await getBytesFromFile({ file })

  const filesInfo = filetypeinfo(fileBytes)

  const extensions = filesInfo.map((v) => v.extension)
  return extensions.filter(Boolean) as string[]
}

export const getExtensionFromFile = async ({
  file,
}: {
  file: File
}): Promise<string> => {
  const fileBytes = await getBytesFromFile({ file })

  const filesInfo = filetypeinfo(fileBytes)

  return filesInfo[0].extension ?? ''
}

export const getBytesFromFile = ({
  file,
}: {
  file: File
}): Promise<Uint8Array> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer)
      resolve(bytes)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })

export const removeExtensionFromFileName = (
  fileName: string | undefined
): string | undefined => {
  if (!fileName) {
    return undefined
  }
  return fileName.replace(/\.[^/.]+$/, '')
}

export const getFileNameWithMaxLength = ({
  filename,
  maxLength,
}: {
  filename: string
  maxLength: number
}): string => {
  const filenameWithoutExtension =
    removeExtensionFromFileName(filename) ?? filename

  const truncatedFilename = filenameWithoutExtension.slice(0, maxLength)
  return truncatedFilename
}

export const preview = ({
  url,
  file,
}: {
  url?: string
  file?: string | null
}) => {
  if (!url && !file) {
    return undefined
  }

  if (file) {
    window.open(
      typeof file === 'string' ? file : URL.createObjectURL(file),
      '_blank'
    )
  } else {
    window.open(url, '_blank')
  }
}

export const download = ({
  content,
  fileName,
  contentType,
}: {
  content: any
  fileName: string
  contentType: string
}) => {
  const a = document.createElement('a')
  const file = new Blob([content], { type: contentType })
  a.href = URL.createObjectURL(file)
  a.download = fileName
  a.click()
}

export const open = ({
  content,
  contentType,
}: {
  content: Buffer
  contentType: string
}) => {
  const file = new Blob([content], { type: contentType })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

export const getImageDimensions = (
  file: File
): Promise<{
  width: number
  height: number
}> =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }

    img.src = URL.createObjectURL(file)
  })

const exports = {
  removeExtensionFromFileName,
  getExtensionFromFile,
  getFileNameWithMaxLength,
  isFileBiggerThan,
  preview,
  download,
  open,
  getImageDimensions,
  isImage,
  isFileExtensionAllowed,
  AllowedDocumentMimeTypes,
  AllowedImageMimeTypes,
  AllowedVideoMimeTypes,
  AllowedStreamChatMimeTypes,
  getMimeTypeFromFileExtension,
  DEFAULT_MAX_FILE_SIZE_IN_MB,
  CSV_FILE_MIME_TYPE,
}

export default exports
