import { Suspense, lazy, useMemo } from 'react'

import authHook from '@hooks/useAuth'
import featuresHook from '@hooks/useFeatures'

import { withStreamChatManagedConnector } from '@HOCs/withStreamChatManagedConnector'

import { StreamChatAnonymousConnection } from '@components/StreamChatAnonymousConnection'
import { StreamChatPrivateConnection } from '@components/StreamChatPrivateConnection'

const BuyerOrVisitorSolutionView = lazy(() =>
  import('@pages/SolutionView').then((module) => {
    return {
      default: module.SolutionView,
    }
  })
)

const BuyerOrVisitorPublicSolution_v2 = lazy(() =>
  import('@pages/PublicSolution_v2').then((module) => {
    return {
      default: module.PublicSolution_v2,
    }
  })
)

const BuyerSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatPrivateConnection,
  { authenticated: true }
)

const BuyerPublicSolution_V2_ViewWithChatConnection =
  withStreamChatManagedConnector(
    BuyerOrVisitorPublicSolution_v2,
    StreamChatPrivateConnection,
    { authenticated: true }
  )

const VisitorSolutionViewWithChatConnection = withStreamChatManagedConnector(
  BuyerOrVisitorSolutionView,
  StreamChatAnonymousConnection,
  { authenticated: false }
)

const VisitorSolution_V2_ViewWithChatConnection =
  withStreamChatManagedConnector(
    BuyerOrVisitorPublicSolution_v2,
    StreamChatAnonymousConnection,
    { authenticated: false }
  )

const SolutionViewMiddleware = () => {
  const { isFetchingUserData, isBuyer } = authHook.useAuth()
  const { availableFeatures, isLoadingAvailableFeatures } =
    featuresHook.useFeatures()

  const ComponentToRender = useMemo(() => {
    if (isBuyer()) {
      if (availableFeatures.solutionV2) {
        return <BuyerPublicSolution_V2_ViewWithChatConnection />
      }

      return <BuyerSolutionViewWithChatConnection />
    }

    if (availableFeatures.solutionV2) {
      return <VisitorSolution_V2_ViewWithChatConnection />
    }

    return <VisitorSolutionViewWithChatConnection />
  }, [availableFeatures.solutionV2, isBuyer])

  if (isFetchingUserData || isLoadingAvailableFeatures) {
    return <></>
  }

  return <Suspense fallback={<></>}>{ComponentToRender}</Suspense>
}

export { SolutionViewMiddleware }
