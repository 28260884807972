import Resizer from 'react-image-file-resizer'

import FileUtils, { DEFAULT_MAX_FILE_SIZE_IN_MB } from '@utils/files'

import { errorMessages, errorTypes } from './errors'
import { FileNotImageException, FileTooLargeException } from './exceptions'

export const COSMO_AVATAR = '/images/chatbot/cosmo.png'
export const COSMO_AVATAR_DISABLED = '/images/chatbot/cosmo-disabled.png'

export const DEFAULT_AVATAR = '/images/no-avatar.png'
export const DEFAULT_VENDOR_COMPANY_LOGO = '/images/default-logo.png'
export const DEFAULT_VENDOR_COMPANY_BANNER = '/images/default-banner.png'

export const DEFAULT_AVATAR_MAX_WIDTH = 256
export const DEFAULT_AVATAR_MAX_HEIGHT = 256

export const DEFAULT_LOGO_MAX_WIDTH = 256
export const DEFAULT_LOGO_MAX_HEIGHT = 256

export const DEFAULT_BANNER_MAX_WIDTH = 1920
export const DEFAULT_BANNER_MAX_HEIGHT = 770

export interface ICompressImageConfig {
  image: File
  compressFormat?: string
  imageOutputType?: string
  quality?: number
  alwaysCompress?: boolean
  onlyCompressIfBiggerThanMb?: number
  maxWidth?: number
  maxHeight?: number
  maxWidthPercentage?: number
  maxHeightPercentage?: number
  minWidthPercentage?: number
  minHeightPercentage?: number
}

const DEFAULT_IMAGE_COMPRESSION_CONFIG = {
  compressFormat: 'JPEG',
  outputType: 'file',
  alwaysCompress: true,
  onlyCompressIfBiggerThanMb: 2,
  maxWidth: 1920,
  maxHeight: 1080,
  maxWidthPercentage: 70,
  maxHeightPercentage: 70,
  quality: 90,
}

const DEFAULT_BASE64_COMPRESSION_CONFIG = {
  compressFormat: 'JPEG',
  maxWidth: 800,
  maxHeight: 800,
  quality: 70,
}

export const compressImage = async ({
  image,
  onlyCompressIfBiggerThanMb,
  compressFormat,
  imageOutputType = DEFAULT_IMAGE_COMPRESSION_CONFIG.outputType,
  quality = DEFAULT_IMAGE_COMPRESSION_CONFIG.quality,
  maxWidth = DEFAULT_IMAGE_COMPRESSION_CONFIG.maxWidth,
  maxHeight = DEFAULT_IMAGE_COMPRESSION_CONFIG.maxHeight,
  maxWidthPercentage = DEFAULT_IMAGE_COMPRESSION_CONFIG.maxWidthPercentage,
  maxHeightPercentage = DEFAULT_IMAGE_COMPRESSION_CONFIG.maxHeightPercentage,
}: ICompressImageConfig) => {
  const isImage = await FileUtils.isImage({
    file: image,
  })

  if (!isImage) {
    throw new FileNotImageException(
      errorMessages[errorTypes.INVALID_IMAGE_TYPE]
    )
  }

  if (
    FileUtils.isFileBiggerThan({
      file: image,
      sizeInMb: DEFAULT_MAX_FILE_SIZE_IN_MB,
    })
  ) {
    const message = `${
      errorMessages[errorTypes.FILE_SIZE_TOO_LARGE]
    } ${DEFAULT_MAX_FILE_SIZE_IN_MB}MB`

    throw new FileTooLargeException(message)
  }

  if (
    onlyCompressIfBiggerThanMb &&
    !FileUtils.isFileBiggerThan({
      file: image,
      sizeInMb: onlyCompressIfBiggerThanMb,
    })
  ) {
    return image
  }

  const imageDimensions = await FileUtils.getImageDimensions(image)

  const preferredMaxWidth =
    maxWidth ?? imageDimensions.width * (maxWidthPercentage / 100)
  const preferredMaxHeight =
    maxHeight ?? imageDimensions.height * (maxHeightPercentage / 100)

  const preferredCompressFormat =
    compressFormat ?? image.name.split('.').pop() ?? compressFormat

  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        image,
        preferredMaxWidth,
        preferredMaxHeight,
        preferredCompressFormat!,
        quality,
        0,
        (resizedImage) => {
          resolve(resizedImage)
        },
        imageOutputType
      )
    } catch (err: any) {
      err.message = 'Error uploading the image'
      reject(err)
    }
  })
}

export const getCompressedBase64FromFile = async (
  file: File,
  config = DEFAULT_BASE64_COMPRESSION_CONFIG
) => {
  const compressedFile = await compressImage({
    image: file,
    compressFormat: config.compressFormat,
    imageOutputType: 'base64',
    maxWidth: config.maxWidth,
    maxHeight: config.maxHeight,
    quality: config.quality,
    alwaysCompress: true,
  })
  return compressedFile as string
}

export const getBase64FromFile = async (file: File) => {
  if (await FileUtils.isImage({ file })) {
    return getCompressedBase64FromFile(file)
  }
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

const exports = {
  compressImage,
  getBase64FromFile,
  getCompressedBase64FromFile,
}

export default exports
