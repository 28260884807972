import type { SetOptional } from 'type-fest'

import api from '@libs/api'

import { EntityMatchesDataExtras } from '@pages/buyer/SolutionMatches'

import { handleServiceError } from '@utils/errors'
import { getTimeZone } from '@utils/timezones'
import type {
  IApiData,
  ICompany,
  IQueryFilters,
  ISolution,
  IVendor,
} from '@utils/types'
import { buildQueryString } from '@utils/url'

export type VendorMatchData = Pick<IVendor, 'id' | 'userId'> & {
  company: Pick<ICompany, 'id' | 'name' | 'type' | 'logo'>
} & {
  solutions: Pick<ISolution, 'id'>[]
}

const getByUserId = async (): Promise<IVendor> => {
  try {
    const response = await api.get({
      path: '/vendors/profile',
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAllActive = async (
  queryFilters?: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IVendor[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: '/vendors/active',
      queryString,
    })

    const { data, count } = response

    const mappedData = data.map((v: any) => {
      return {
        ...v,
        ...(v.createdDate && { createdDate: new Date(v.createdDate) }),
        ...(v.updatedDate && { updatedDate: new Date(v.updatedDate) }),
      }
    })

    return {
      data: mappedData,
      count,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAll = async (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IVendor[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = (await api.get({
      path: '/vendors',
      queryString,
    })) as IApiData<IVendor[]>

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAllForSelect = async (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
): Promise<IApiData<IVendor[]>> => {
  const queryString = buildQueryString(queryFilters)

  try {
    const response = (await api.get({
      path: '/vendors/select',
      queryString,
    })) as IApiData<IVendor[]>

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAllForEnterpriseSelect = async (): Promise<IVendor[]> => {
  try {
    const response = (await api.get({
      path: '/vendors/select/enterprise',
    })) as IVendor[]

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const findAllMatchingVendors = async (
  queryFilters: IQueryFilters & { isSavedSearch: boolean }
): Promise<IApiData<VendorMatchData[], EntityMatchesDataExtras>> => {
  const { isSavedSearch } = queryFilters

  const queryString = buildQueryString(queryFilters)

  try {
    const response = await api.get({
      path: '/vendors/matches',
      queryString: {
        ...queryString,
        savedSearch: isSavedSearch.toString(),
      },
    })

    const { data, count, extras } = response

    return {
      count,
      data,
      extras,
    }
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const fetchCsvData = async (
  queryFilters: SetOptional<IQueryFilters, 'page' | 'limit'>
) => {
  const queryString = {
    ...buildQueryString(queryFilters),
    timeZone: getTimeZone(),
  }

  try {
    const response = await api.csv({
      path: '/vendors/csv',
      queryString,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const updateProfile = async (data: {
  firstName: string
  lastName: string
  officialTitle: string
  personalEmail?: string
  phone?: string
  avatar?: File | null
  company?: ICompany
}) => {
  try {
    const formData = api.buildFormData(data)

    await api.put({
      headers: { 'Content-Type': 'multipart/form-data' },
      path: '/vendors/profile',
      data: formData,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const createVendorFromBuyer = async ({
  email,
  password,
  companyType,
}: {
  email?: string
  password?: string
  companyType: string
}) => {
  try {
    const response = await api.post({
      path: '/vendors/convert/buyer',
      authenticate: false,
      data: { credentials: { email, password }, companyType },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const update = async (data: {
  id: string
  email: string
  status: string
  statusOption?: string
  firstName?: string
  lastName?: string
  type?: string
  companyName?: string
  officialTitle?: string
  personalEmail?: string
  phone?: string
  companyAddress1?: string
  companyAddress2?: string
  companyCity?: string
  companyState?: string
  companyZipCode?: string
  companyPhoneNumber?: string
  companyPhoneExtension?: string
  resetPasswordAndSendEmail?: boolean
}) => {
  try {
    return await api.put({
      path: `/vendors/${data.id}`,
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchUpdate = async (data: { vendorIds: string[]; status: string }) => {
  try {
    return await api.put({
      path: '/vendors/batch',
      data,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const batchDelete = async ({ vendorIds }: { vendorIds: string[] }) => {
  try {
    return await api.remove({
      path: '/vendors/batch',
      queryString: {
        vendorIds,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const remove = async ({ id }: { id?: string } = {}) => {
  try {
    const path = id
      ? `/vendors/${id}/delete-account`
      : '/vendors/delete-account'

    return await api.remove({
      path,
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const recoverAccount = async () => {
  try {
    return await api.post({
      path: '/vendors/recover-account',
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getById = async (id: string): Promise<IVendor> => {
  try {
    const response = await api.get({
      path: `/vendors/${id}`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const getLegacyById = async (
  id: string
): Promise<
  Pick<IVendor, 'firstName' | 'lastName' | 'email'> & {
    type: string
  }
> => {
  try {
    const response = await api.get({
      path: `/vendors/legacy/${id}`,
      authenticate: false,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const create = async ({
  firstName,
  lastName,
  email,
  companyType,
  companyName,
  officialTitle,
  personalEmail,
  phone,
  companyAddress1,
  companyAddress2,
  companyCity,
  companyState,
  companyZipCode,
  companyPhoneNumber,
  companyPhoneExtension,
  legacy,
  externalId,
  additionalEmails,
  importing,
  enterprise,
}: {
  firstName: string
  lastName: string
  email: string
  companyType: string
  companyName?: string
  officialTitle?: string
  personalEmail?: string
  phone?: string
  companyAddress1?: string
  companyAddress2?: string
  companyCity?: string
  companyState?: string
  companyZipCode?: string
  companyPhoneNumber?: string
  companyPhoneExtension?: string
  legacy?: boolean
  externalId?: string
  additionalEmails?: string[]
  importing?: boolean
  enterprise?: boolean
}) => {
  try {
    const path = importing ? '/vendors/import' : '/vendors'

    return await api.post({
      path,
      data: {
        firstName,
        email,
        lastName,
        companyType,
        companyName,
        officialTitle,
        personalEmail,
        phone,
        companyAddress1,
        companyAddress2,
        companyCity,
        companyState,
        companyZipCode,
        companyPhoneNumber,
        companyPhoneExtension,
        legacy,
        externalId: externalId || undefined,
        additionalEmails,
        enterprise,
      },
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const sendWelcomeEmail = async () => {
  try {
    return await api.post({
      path: '/vendors/send-welcome-email',
    })
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const changeChatStatus = async ({
  chatStatus,
  chatStatusOfflineAutomaticResponse,
}: Pick<IVendor, 'chatStatus' | 'chatStatusOfflineAutomaticResponse'>) => {
  try {
    const processedChatStatusOfflineAutomaticResponse =
      chatStatusOfflineAutomaticResponse || null

    await api.put({
      path: '/vendors/chat-status',
      data: {
        chatStatus,
        chatStatusOfflineAutomaticResponse:
          processedChatStatusOfflineAutomaticResponse,
      },
    })
  } catch (err) {
    throw handleServiceError(err)
  }
}

const markSolutionV2OnboardingAsSeen = async () => {
  try {
    const response = await api.post({
      path: '/vendors/solution-v2-onboarding/seen',
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

export default {
  getByUserId,
  updateProfile,
  createVendorFromBuyer,
  findAll,
  findAllForSelect,
  findAllForEnterpriseSelect,
  findAllActive,
  findAllMatchingVendors,
  update,
  batchUpdate,
  batchDelete,
  getById,
  getLegacyById,
  create,
  remove,
  recoverAccount,
  sendWelcomeEmail,
  changeChatStatus,
  fetchCsvData,
  markSolutionV2OnboardingAsSeen,
}
