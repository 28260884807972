import { ForwardRefRenderFunction, forwardRef, useState } from 'react'

import {
  FormHelperTextProps,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material'

import ErrorIcon from '@mui/icons-material/Error'

import { inputLabelPropsShrinkSx, textFieldWithLeftIconSx } from './styles'

const DEFAULT_VALUE_WITH_CURRENCY_MASK = '$  '

export type TextFieldProps =
  | MuiTextFieldProps & {
      success?: boolean
      error?: boolean
      cursor?: string
      inputTestId?: string
      multiline?: boolean
      showErrorIcon?: boolean
      showMaxLengthInHelperText?: boolean
      leftIcon?: React.ReactNode
      parentValue?: number | string
    }

const ForwardedTextField: ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    cursor,
    success,
    value,
    error,
    inputTestId,
    multiline = false,
    showErrorIcon,
    sx,
    inputProps,
    InputLabelProps,
    showMaxLengthInHelperText,
    helperText,
    leftIcon,
    parentValue,
    onFocus,
    onBlur,
    ...rest
  }: TextFieldProps,
  ref
) => {
  const theme = useTheme()

  const [isFocused, setIsFocused] = useState(false)

  const buildCursor = () => {
    if (!cursor) {
      return undefined
    }

    return {
      '& .MuiOutlinedInput-root input': {
        cursor: 'pointer',
      },
    }
  }

  const buildBorderColor = () => {
    if (error || (isFocused && !success)) {
      return undefined
    }

    if (success) {
      return {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.success.main,
        },
      }
    }

    if (
      rest.variant === 'outlined' &&
      parentValue !== undefined &&
      parentValue !== '' &&
      parentValue !== DEFAULT_VALUE_WITH_CURRENCY_MASK
    ) {
      return {
        '& fieldset': {
          borderColor: theme.palette.text.primary,
        },
      }
    }

    if (!!value) {
      return {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.text.primary,
        },
      }
    }

    return undefined
  }

  const buildInputLabelProps = () => {
    if (success) {
      return {
        ...InputLabelProps,
        style: {
          color: theme.palette.success.main,
        },
      }
    }

    if (InputLabelProps?.shrink) {
      return {
        ...InputLabelProps,
        sx: inputLabelPropsShrinkSx({
          variant: rest.variant,
          hasValue: true,
        }),
      }
    }

    if (leftIcon) {
      return {
        ...InputLabelProps,
        shrink: isFocused,
        sx: isFocused
          ? undefined
          : inputLabelPropsShrinkSx({
              variant: rest.variant,
              hasValue: !!value,
            }),
      }
    }

    return InputLabelProps ?? undefined
  }

  const buildFormHelperTextProps = () => {
    const formHelperTextProps: Partial<
      FormHelperTextProps<'p', Record<string, unknown>>
    > = {}

    if (success) {
      formHelperTextProps.style = {
        color: theme.palette.success.main,
      }
    }

    if (isShowMaxLengthInHelperText()) {
      formHelperTextProps.sx = { textAlign: 'right' }
    }

    return formHelperTextProps
  }

  const handleInternalOnFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFocused(true)
    onFocus?.(event)
  }

  const handleInternalOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFocused(false)
    onBlur?.(event)
  }

  const isShowMaxLengthInHelperText = () =>
    showMaxLengthInHelperText && !!inputProps?.maxLength && isFocused

  const buildHelperText = () => {
    if (isShowMaxLengthInHelperText()) {
      return getMaxLengthText({
        value: value as string,
        maxLength: inputProps?.maxLength,
      })
    }

    if (helperText) {
      return helperText
    }

    return undefined
  }

  const getMaxLengthText = ({
    value,
    maxLength,
  }: {
    value: string
    maxLength: number | undefined
  }) => {
    const formattedValue = value.replace(/\r\n/g, '\n')
    const textLength = formattedValue.length

    return `${textLength}/${maxLength}`
  }

  return (
    <MuiTextField
      inputRef={ref}
      onBlur={handleInternalOnBlur}
      onFocus={handleInternalOnFocus}
      error={!isShowMaxLengthInHelperText() ? error : undefined}
      value={value}
      multiline={multiline}
      InputLabelProps={{
        ...buildInputLabelProps(),
      }}
      FormHelperTextProps={buildFormHelperTextProps()}
      {...rest}
      helperText={buildHelperText()}
      sx={
        {
          ...buildBorderColor(),
          ...buildCursor(),
          ...(leftIcon && textFieldWithLeftIconSx),
          ...sx,
        } as SxProps<Theme>
      }
      InputProps={{
        ...rest.InputProps,
        ...(error && showErrorIcon
          ? {
              endAdornment: (
                <ErrorIcon
                  sx={{ color: `${theme.palette.error.light} !important` }}
                />
              ),
            }
          : {}),
        ...(leftIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">{leftIcon}</InputAdornment>
              ),
            }
          : {}),
      }}
      inputProps={{
        'data-testid': inputTestId,
        ...inputProps,
      }}
    />
  )
}

const TextField = forwardRef(ForwardedTextField)
export { TextField }
